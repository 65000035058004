import React from 'react'
import Img from 'gatsby-image'

// Components
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
// Data
import textMEI from '../../data/cartao-inter/data-limite-credito-mei.json'
import textPJ from '../../data/cartao-inter/data-limite-credito-pj.json'
import usePageQuery from '../../../pageQuery'

import { Section } from './style'

const WIDTH_MD = 768

type modalProps = {
  setOpenModal1: Function;
  page?: string;
}

const CartaoInterMastercard = ({ setOpenModal1, page }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)
  const data = usePageQuery()
  let pageText = textMEI

  if (page === 'PJ') {
    pageText = textPJ
  }

    return (
      <Section className='py-5 d-flex position-relative align-items-center justify-content-md-between'>
        <div className='container'>
          <div className='row align-items-center justify-content-md-between'>
            <div className='col-12 col-md-6 col-lg-5 mb-3 mb-md-0 order-md-last'>
              <Img fluid={data[pageText.image].fluid} alt={pageText.altImage} />
            </div>
            <div className='col-12 col-md-6 col-lg-5'>
              <h2
                className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4'
                dangerouslySetInnerHTML={{ __html: pageText.title }}
              />
              <p
                className='fs-18 lh-22 text-grayscale--400'
                dangerouslySetInnerHTML={{ __html: pageText.description }}
              />
              <div
                className='pt-3'
                onClick={() => sendDatalayerEvent({
                  section: 'dobra_3',
                  section_name: pageText.title,
                  element_action: 'click button',
                  element_name: 'Acessar minha conta',
                  c_page: window.location.href,
                })}
              >
                { windowWidth > WIDTH_MD
                  ? (
                    <button
                      className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                      onClick={() => setOpenModal1(true)}
                    >
                      Acessar minha conta
                    </button>)
                  : (
                    <a
                      href={pageText.deepLink}
                      className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                    >Acessar minha conta
                    </a>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
}

CartaoInterMastercard.defaultProps = {
  page: 'MEI',
}

export default CartaoInterMastercard
