import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media ${device.tablet} {
    min-height: 416px;
  }
  @media ${device.desktopLG} {
    min-height: 504px;
  }
  @media ${device.desktopXL} {
    min-height: 650px;
  }

  button, a { 
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }  
  }

  .gatsby-image-wrapper {

    @media ${device.desktopXXL} {
      max-width: 456px;
      float: right;
    }
  }
`
