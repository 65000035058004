import React, { useState } from 'react'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from '../../style'

// components
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import AtivacaoModal1 from 'src/components/UI/Forms/InterAtivacaoClientesForm1'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

// Sections
import Header from '../../../volte-para-o-inter/limite-credito/sections/header/_index'
import LimiteNaHora from '../../../volte-para-o-inter/limite-credito/sections/limiteNaHora/_index'
import CartaoInterMastercard from '../../limite-credito/sections/cartao-inter-mastercard/_index'
import LibereMaisLimite from '../../limite-credito/sections/libereMaisLimite/_index'
import { Modal } from 'src/components/Modal'
import HelpCenter from '../../helpCenter/_index'

// Data
import pageContext from './pageContext.json'

const LimiteCreditoMEI = () => {
  const page = 'MEI'
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModal1, setOpenModal1 ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )
  const openAccountPJFormModal1 = domReady && (
    <Modal isModalOpen={openModal1} setIsModalOpen={setOpenModal1} locationToRender={document.body}>
      <AtivacaoModal1 closeModal={() => setOpenModal1(false)} page={page} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountPJFormModal}
        {openAccountPJFormModal1}
        <Header page={page} />
        <LimiteNaHora setOpenModal1={setOpenModal1} page={page} />
        <CartaoInterMastercard setOpenModal1={setOpenModal1} page={page} />
        <LibereMaisLimite setOpenModal={setOpenModal} page={page} setOpenModal1={setOpenModal1} />
        <HelpCenter bgColor='bg-white' />
      </Layout>
    </Wrapper>
  )
}

export default LimiteCreditoMEI
